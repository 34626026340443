import * as React from 'react';

/**
 * Sets up `react-select`
 */
import ReactSelect from 'react-select';

// Styles for react-select are global
// eslint-disable-next-line no-unused-vars
import styles from './Select.scss';

export default function Select(props: mixed): React.Node {
  return (
    <ReactSelect
      classNamePrefix="react-select"
      styles={{
        valueContainer: (baseStyles, _state) => ({
          ...baseStyles,
          padding: '1px 8px'
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#36ad95' : '#ccc',
          borderRadius: '3px',
          borderWidth: '2px',
          boxShadow: 'none',
          minHeight: '39px',
          '&:hover': {
            borderColor: state.isFocused ? '#36ad95' : '#ccc'
          }
        }),
        multiValue: (baseStyles, _state) => ({
          ...baseStyles,
          backgroundColor: 'rgba(26, 188, 156, 0.08)',
          border: 'solid 1px rgba(26, 188, 156, 0.5)',
          color: '#1abc9c'
        }),
        dropdownIndicator: (baseStyles, _state) => ({
          ...baseStyles,
          padding: '7px 8px'
        }),
        clearIndicator: (baseStyles, _state) => ({
          ...baseStyles,
          padding: '7px 8px'
        })
      }}
      {...props}
    />
  );
}
